<template>
  <div class="layout-content">
    <home-head />
    <div class="layout-content-box">
      <router-view />
    </div>
    <home-footer v-if="isFooter" />
  </div>
</template>

<script>
import homeHead from "../components/homeHead.vue";
import homeFooter from "../components/homeFooter.vue";
export default {
  components: {
    homeHead,
    homeFooter,
  },
  data() {
    return {
      isFooter: true,
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        console.log("当前页面路由：", to);

        if (to.meta.footer === false) {
          this.isFooter = false;
        } else {
          this.isFooter = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-content {
  min-height: 100%;
  // background: #F6F6F6;
  // .layout-content-box {
  //   min-height: calc(100vh - 56px);
  // }
}
</style>
