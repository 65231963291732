<template>
  <div class="home-head-box">
    <span class="home-head-logo" @click="$router.push('/')">
      <img src="@/assets/img/logo@3x.png" alt="logo" />
    </span>
    <div class="home-head-menu">
      <div class="home-head-menu-closed" v-if="isMenu">
        <span @click="closeMenu"
          ><img src="@/assets/img/guanbi@2x.png" alt="guanbi" class="guanbi"
        /></span>
      </div>
      <van-dropdown-menu
        :close-on-click-overlay="false"
        :close-on-click-outside="false"
      >
        <van-dropdown-item ref="user" @open="openedMenu('user')">
          <template slot="title">
            <img
              v-if="!isLogin"
              :src="require('@/assets/img/user@3x.png')"
              alt="user"
              class="user-tx"
            />
            <v-img
              v-else
              :attachment-id="userInfo.profilePictureAttmId"
              :default-img="require('@/assets/img/user.png')"
              alt="user"
              class="user-tx"
            />


          </template>
          <div class="home-head-user-box">
            <div class="home-head-user" v-if="isLogin" @click="toverified">
              <div class="head-user-left">
              <span>
                <v-img
                  :attachment-id="userInfo.profilePictureAttmId"
                  :default-img="require('@/assets/img/user.png')"
                  alt="user"
                />
              </span>
              <p>{{ userInfo.realName || userInfo.phone }}</p>
              </div>
              <div class="head-user-right">
                <p>账号设置</p>
                <div>
                <img src="@/assets/img/jiantou.png" alt="">
                </div>
              </div>
            </div>
            <p :class="{'active':$route.path == '/myConsult'}" :data-url='$route.path' @click="userCenter('/myConsult')">我的咨询</p>
<!--            <p :class="{'active':$route.path == '/myDev'}" :data-url='$route.path' @click="userCenter('/myDev')">我申请的开发板</p>-->
            <p :class="{'active':$route.path == '/myDemand'}" :data-url='$route.path' @click="userCenter('/myDemand')">我的国产替代迁移需求</p>
            <!-- <p :class="{'active':$route.path == '/myQuestionnaire'}" :data-url='$route.path' @click="userCenter('/myQuestionnaire')">我的问卷</p> -->
            <p :class="{'active':$route.path == '/myCourse'}" :data-url='$route.path' @click="userCenter('/myCourse')">我的课程</p>
            <div class="home-head-user-login">
              <template v-if="!isLogin">
                <van-button
                  class="btn-red"
                  size="small"
                  style="margin-right: 2%"
                  @click="register"
                  >注册</van-button
                >
                <van-button class="btn-border-red" size="small" @click="login"
                  >登录</van-button
                >
              </template>
              <van-button v-else class="btn-white" size="small" @click="logout"
                >退出登录</van-button
              >
            </div>
          </div>
        </van-dropdown-item>
        <van-dropdown-item ref="menu" @open="openedMenu('menu')">
          <template slot="title">
            <img
              src="@/assets/img/headzhankai@3x.png"
              alt="guanbi"
              class="headzhankai"
            />
          </template>
          <div class="home-head-nav">
            <div class="home-head-nav-title">
              <p
                v-for="(el, index) in menuTitle"
                :key="index"
                @click="menuTitleIndex = index"
                :class="{ active: menuTitleIndex == index }"
              >
                <span>{{ el }}</span>
              </p>
            </div>
            <div class="home-head-nav-list">
              <div>
                <p @click="headUrl(menuList[menuTitleIndex].url)">
                  {{ menuList[menuTitleIndex].title }}
                  <img src="@/assets/img/cdjt.png" alt="" />
                </p>
                <div>
                  <p
                    v-for="(el, index) in menuList[menuTitleIndex].list"
                    :key="index"
                    @click="headUrl(el.url)"
                  >
                    {{ el.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VImg from '@/components/VImg'

export default {
  components: { VImg },
  computed: mapGetters(['isLogin', 'userInfo']),
  data () {
    return {
      menuTitle: [
        '硬件产品',
        '解决方案',
        '开发资源',
        '课程培训',
        '最新调研',
        '走进鸿联'
      ],
      menuList: [
        {
          title: '找｜硬件产品首页',
          url: '/product',
          list: []
        },
        {
          title: '寻｜解决方案首页',
          url: `/solution/searchList/?index=1`,
          list: [
            {
              title: '鸿蒙方案',
              url: `/solution/searchList/?index=1`
            }
            ,{
              title:'数字化转型',
              url: `/solution/searchList/?index=2`
            }
          ]
        },
        {
          title: '享｜开发资源首页',
          url: '/product/productList',
          list: [
            {
              title: '开发板',
              url: '/product/list'
            },
            {
              title: 'SOC',
              url: '/product/list?type=soc'
            }
          ]
        },
        {
          title: '课程培训首页',
          url: '/course',
          list: [
            {
              title: 'OpenHarmony',
              url: '/course?type=001'
            },
            {
              title: '鸿蒙',
              url: '/course?type=002'
            }
          ]
        },
        {
          title: '最新调研首页',
          url: '/questionnaire',
          list: [
            //   {
            //   title:'盐城市'
            // },{
            //   title:'重庆市'
            // },{
            //   title:'苏州市'
            // }
          ]
        },
        {
          title: '走进鸿联首页',
          url: '/digitizing',
          list: [
            //   {
            //   title:'关于鸿联'
            // },
            {
              title: '数字化转型',
              url: '/digitizing'
            }
          ]
        }
      ],
      menuTitleIndex: 0,
      isMenu: false,
      openedType: '',
    }
  },
  methods: {
    toverified(){
      this.noneMenu()
      this.$router.push(`/verified`)
      this.isMenu = false
    },
    userCenter (url) {
      this.noneMenu()
      this.isMenu = false
      this.$router.push(url)
    },
    openedMenu (type) {
      this.openedType = type
      this.isMenu = true
    },
    closeMenu () {
      this.noneMenu()
      this.isMenu = false
    },
    headUrl (url) {
      this.noneMenu()
      this.isMenu = false
      this.$router.push(url)
    },
    register () {
      this.noneMenu()
      this.isMenu = false
      this.$router.push('/register')
    },
    login () {
      this.noneMenu()
      this.isMenu = false
      this.$router.push('/login')
    },
    logout () {
      this.noneMenu()
      this.isMenu = false
      this.$router.push('/logout')
      // localStorage.removeItem("openId");
    },
    noneMenu(){
      if (this.openedType == 'user') {
        this.$refs.user.toggle()
      } else {
        this.$refs.menu.toggle()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-head-box {
  height: 56px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0,0,0,0.0600);
  .home-head-logo {
    > img {
      display: block;
      max-width: 80px;
      max-height: 24px;
    }
  }
  .home-head-menu {
    height: 100%;
    position: relative;
    .home-head-menu-closed {
      position: absolute;
      background: #fff;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 12;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > span {
        width: 24px;
        > img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
.home-head-user-box {
  border-top: 1px solid #eee;
  padding: 0 16px 16px;
  > p {
    height: 52px;
    line-height: 52px;
    font-size: 14px;
    color: #000000;
    border-bottom: 1px solid #eee;
    &.active {
      // color: #ea0b06;
    }
  }
  .home-head-user-login {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    > button {
      width: 49%;
    }
  }
}
.home-head-nav {
  border-top: 1px solid #eee;
  background: #fff;
  display: flex;
  .home-head-nav-title {
    width: 137px;
    background: #f1f1f3;
    padding: 12px 0;
    > p {
      display: flex;
      align-items: center;
      height: 48px;
      > span {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        padding-left: 14px;
        border-left: 2px solid transparent;
      }
      &.active {
        background: linear-gradient(
          90deg,
          #ffffff 0%,
          #ffffff 54%,
          rgba(255, 255, 255, 0) 100%
        );
        > span {
          border-color: #ea0b06;
        }
      }
    }
  }
  .home-head-nav-list {
    flex: 1;
    padding: 24px 20px 0;
    > div {
      > p {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        color: #000000;
        margin-bottom: 24px;
        > img {
          width: 18px;
          margin-left: 8px;
        }
      }
      > div {
        > p {
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          margin-bottom: 24px;
        }
      }
    }
  }
}
.home-head-user {
  display: flex;
  padding-top: 16px;
  align-items: center;
  justify-content: space-between;
  .head-user-left{
    display: flex;
    align-items: center;
    > span {
      width: 44px;
      height: 44px;
      margin-right: 12px;
      > img {
        display: block;
      /*  width: 100%;
        height: 100%;*/
        width: 42px;
        height: 42px;
        border-radius: 42px!important;
      }
    }
    > p {
      font-size: 14px;
      color: #000000;
    }
  }
  .head-user-right{
    display: flex;
    align-items: center;
    >p{
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      opacity: 1;
      margin-right: 12px;
    }
    >div{
      max-width: 18px;
      max-height: 18px;
      >img{
        display: block;
        max-width: 100%;
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
<style>
.home-head-menu .van-dropdown-menu__bar {
  height: 100%;
  background: none;
  box-shadow: none;
}
.home-head-menu .van-ellipsis > img {
  width: 24px;
  display: block;
}
.home-head-menu .van-ellipsis>.user-tx{
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: block;
}
.home-head-menu .van-dropdown-menu {
  height: 100%;
}
.home-head-menu .van-dropdown-menu__title {
  padding: 0;
  margin-left: 16px;
}
.home-head-menu .van-dropdown-menu__title::after {
  display: none;
}
/* .van-dropdown-menu__bar--opened .user-tx{
  display: none !important;
}
.van-dropdown-menu__bar--opened .headzhankai{
  display: none !important;
}
.guanbi{
  display: none !important;
}
.van-dropdown-menu__bar--opened .guanbi{
  display: block !important;
} */
</style>
