<template>
  <div class="home-footer">
    <div class="home-footer-info">
      <span>售前咨询热线</span>
      <p @click="callPhone('400-025-6877')"><img src="@/assets/img/dh@3x.png" alt="dh" /> 400-025-6877</p>
      <p>
        <img src="@/assets/img/yx@3x.png" alt="yx" />
        IIG.RDD.honglian@chinasofti.com
      </p>
      <div>
        <span @click="footerCode(0)"
          ><img src="@/assets/img/qywx@3x.png" alt="dh" />企业微信</span
        >
        <span @click="footerCode(1)"
          ><img src="@/assets/img/fwh@3x.png" alt="dh" />服务号</span
        >
        <span @click="footerCode(2)"
          ><img src="@/assets/img/gzh@3x.png" alt="dh" />公众号</span
        >
      </div>
    </div>
    <div class="home-footer-copyright">
      <p>
        Copyright © 2013- Copyright © 北京掌中无限信息技术有限公司 All rights
        reserved
      </p>
      <p>工信部备案号：京ICP备09083730号</p>
    </div>
    <div class="home-footer-consult udesk" :class="{'scroll':isScroll}">
      <img src="@/assets/img/zixun@3x.png" alt="lx" />
    </div>
    <van-popup
      v-model="codeShow"
      class="home-footer-code-popup"
      :close-on-click-overlay="false"
    >
      <div class="home-footer-code">
        <img v-show="codeIndex == 0" src="@/assets/img/wxm.png" alt="code" />
        <img v-show="codeIndex == 1" src="@/assets/img/fwhm.png" alt="code" />
        <img v-show="codeIndex == 2" src="@/assets/img/gzhm.jpeg" alt="code" />
        <span>长按或截图保存</span>
        <p @click="codeShow = false">
          <img src="@/assets/img/codebg.png" alt="gb" />
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codeShow: false,
      codeIndex: 0,
      isScroll:false,
      timer: null,
      scrollT: 0,
      stopScroll: 0
    };
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(){
      clearTimeout(this.timer);
      this.isScroll = true
      this.timer = setTimeout(this.ScrollEnd, 500);
      this.scrollT = document.documentElement.scrollTop || document.body.scrollTop;
    },
    ScrollEnd() {
      this.stopScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (this.stopScroll == this.scrollT) {
        this.isScroll = false
      }
    },
    footerCode(index) {
      this.codeIndex = index;
      this.codeShow = true;
    },
    callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
    consult(){
      this.$router.push('/wordForm')
    }
  },
};
</script>

<style lang="scss" scoped>
.home-footer {
  background: #262626;
  padding: 32px 0;
  .home-footer-info {
    padding: 0 16px 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
    > span {
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
      display: block;
      margin-bottom: 20px;
    }
    > p {
      display: flex;
      margin-top: 16px;
      align-items: center;
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
      > img {
        width: 28px;
        margin-right: 15px;
      }
    }
    > div {
      display: flex;
      margin-top: 24px;
      > span {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.65);
        margin-right: 24px;
        &:last-child {
          margin-right: 0;
        }
        > img {
          width: 20px;
          margin-right: 8px;
        }
      }
    }
  }
  .home-footer-copyright {
    padding: 24px 16px 0;
    > p {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.65);
      text-align: center;
      line-height: 20px;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .home-footer-consult{
    position: fixed;
    right: 0;
    bottom: 80px;
    z-index: 10;
    transition: all .3s;
    >img{
      width: 52px;
      height: 50px;
      display: block;
    }
    &.scroll{
      right: -30px;
    }
  }
}
.home-footer-code {
  > img {
    width: 180px;
    display: block;
    margin: 0 auto 8px;
  }
  > span {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.85);
    display: block;
    line-height: 20px;
    text-align: center;
    margin-bottom: 24px;
  }
  > p {
    > img {
      width: 30px;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
<style>
.home-footer-code-popup {
  background: none;
}
</style>